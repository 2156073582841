<template>
  <div class="Awarp">
    <dashCard
      class="cdzBox9"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">三相不平衡</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
       
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div
        class="card-content1"
        style="justify-content: space-between; display: flex"
      >
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px"
          @change="handleChange"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList"
            :key="key"
            :value="item.assetnumber"
          >
            {{ item.machine_name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <cdzBox9 :option="option" :isModal="true" :orginSearchObj="searchObj" />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "cdzBox9",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
      
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      assetnumber: "",
      itemList: [],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      gridLeft: [],
      gridright: [],
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: [
        "#7CB5EC",
        "#444449",
        "#90ED7D",
        "#B0E2FF",
        "#696969",
        "#98FB98",
      ],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value) {
      this.assetnumber = value;
      this.getData();
    },
    getList() {
      let data = {
        Dept: this.currentSelectDeptInfo.CODE,
      };
      let url = Config.dev_url + "/api-apps/charger/assetList";
      this.$axios.post(url, data).then((res) => {
        this.itemList = res.data.data;
      });
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      this.detailInfo = {};
      let data = {
        assetNumber: this.assetnumber,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      let url = Config.dev_url + "/api-apps/charger/operatingCurve";
      this.$axios.post(url, data).then((res) => {
        let newData = Object.keys(res.data.data);
        var chart1 = {
          categoryData: newData.categoryData,
          seriesData: newData.seriesData,
        };
        this.detailInfo.chart1 = chart1;
        console.log(this.detailInfo.chart1);
        let newarr = [];
        if (newData.length != 0) {
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          if (this.getNull(newarr) == newarr.length) {
            this.gridLeft = [0, 0, 0, 50];
            this.gridright = [0, 40, 0, 0];
          } else {
            this.gridLeft = [0, 0, 0, 0];
            this.gridright = [0, 20, 0, 0];
          }
        }

        this.initEchart();
        this.isLoading = false;
      });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      if (seriesData !== undefined) {
        seriesData.forEach((item, idx) => {
          var obj = {
            type: "line",
            name: item.name,
            data: item.value,
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            smooth: 0.2,
            showSymbol: false,
            yAxisIndex: idx,
            emphasis: {
              focus: "series",
            },
          };
          series.push(obj);
        });
      }
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "0%",
          right: "1%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: "50px",
          top: "6%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: [
          {
            name: "单位(A)",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 10,
            // max: 100
          },
          {
            name: "(kW)",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridright,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
            splitLine: {
              show: false,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 400,
          },
        ],
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },

    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getList();
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.cdzBox9 {
  .card-content1 {
    .content1-line {
      margin-top: 22px;
      display: flex;
      > div {
        height: 68px;
        width: 185px;
        display: flex;
        flex-direction: column;
        > span {
          &:nth-child(1) {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
          }
          &:nth-child(2) {
            font-size: 32px;
            font-weight: 500;
            color: #1d2129;
            line-height: 40px;
            font {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
